import { Tooltip, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { green } from '@mui/material/colors';

const ValidSiiCredentialsFilter = ({ states, setStates }) => {
  const [triggered, setTriggered] = useState(false);
  const { validSiiCredendials, ...rest } = states;
  return (
    <Tooltip title="Filtrar por Cliente con Credenciales Válidas">
      <Avatar
        sx={{
          bgcolor: triggered ? green[700] : 'gray',
          width: 25,
          height: 25,
          cursor: 'pointer',
        }}
        onClick={() => {
          setStates(
            { ...rest,
              validSiiCredendials: !triggered,
            },
          );
          setTriggered(!triggered);
        }}
      >
        C
      </Avatar>
    </Tooltip>
  );
};

ValidSiiCredentialsFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.object.isRequired,
  setStates: PropTypes.func.isRequired,
};

export default ValidSiiCredentialsFilter;
