import React, { useState } from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';
import PropTypes from 'prop-types';

const filterOptions = [
  {
    label: 'Todos los negocios',
    value: 'ALL',
    key: 'everyInvoice-hunting-famring',
  },
  {
    label: 'Farming',
    value: 'Farming',
    key: 'farmingInvoice-hunting-farming',
  },
  {
    label: 'Hunting',
    value: 'Hunting',
    key: 'huntingInvoice-hunting-famring',
  },
];

const HuntingFarmingFilter = ({ setHuntingFarmingCallback }) => {
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0].value);
  const setHuntingFarming = (e) => {
    setSelectedFilter(e.target.value);
    setHuntingFarmingCallback(filterOptions.find((type) => type.value === e.target.value).value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 115 }}>
      <Select
        name="filterType"
        value={selectedFilter}
        onChange={setHuntingFarming}
      >
        {filterOptions.map((filter) => (
          <MenuItem
            value={filter.value}
            key={filter.key}
          >
            {filter.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

HuntingFarmingFilter.propTypes = {
  setHuntingFarmingCallback: PropTypes.func,
};

HuntingFarmingFilter.defaultProps = {
  setHuntingFarmingCallback: () => {},
};

export default HuntingFarmingFilter;
