import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl } from '@mui/material';

const filterOptions = {
  FACTORING: [
    {
      label: 'Estado',
      value: 'ALL',
      key: 'everyInvoice-filter',
      filter: {
        hasRejectedPreoffer: false,
      },
    },
    {
      label: 'Disponibles',
      value: 'AVAILABLE',
      key: 'availableForFinancing-filter',
      filter: {
        availableForFinancing: 'AVAILABLE',
        hasRejectedPreoffer: false,
      },
    },
    {
      label: 'Rechazadas',
      value: 'REJECTED',
      key: 'hasRejectedPreoffer-filter',
      filter: {
        hasRejectedPreoffer: true,
      },
    },
    {
      label: 'En evaluación',
      value: 'EVALUATING',
      key: 'hasEvaluatingPreoffer-filter',
      filter: {
        hasEvaluatingPreoffer: true,
        hasRejectedPreoffer: false,
      },
    },
  ],
  ORDERING: [
    {
      label: 'Estado',
      value: 'ALL',
      key: 'everyInvoice-filter',
      filter: {
      },
    },
    {
      label: 'Disponibles',
      value: 'AVAILABLE',
      key: 'availableForFinancing-filter',
      filter: {
        status: ['Simulation'],
      },
    },
    {
      label: 'Rechazadas',
      value: 'REJECTED',
      key: 'hasRejectedPreoffer-filter',
      filter: {
        status: ['Rejected'],
      },
    },
    {
      label: 'En evaluación',
      value: 'EVALUATING',
      key: 'hasEvaluatingPreoffer-filter',
      filter: {
        status: ['Evaluation'],
      },
    },
  ],
};

const FingoPreOfferFilter = ({ states, setStates, filterType }) => {
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[filterType][0].value);
  const { availableForFinancing, hasRejectedPreoffer, hasEvaluatingPreoffer, ...rest } = states;
  const setPreOfferFiltersEffect = (e) => {
    setSelectedFilter(e.target.value);
    setStates(
      { ...rest,
        ...filterOptions[filterType].find(
          (el) => el.value === e.target.value,
        ).filter,
      },
    );
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 90 }}>
      <Select
        name="filterType"
        value={selectedFilter}
        onChange={setPreOfferFiltersEffect}
      >
        {filterOptions[filterType].map((filter) => (
          <MenuItem
            value={filter.value}
            key={filter.key}
          >
            {filter.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FingoPreOfferFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.object.isRequired,
  filterType: PropTypes.oneOf(['ORDERING', 'FACTORING']).isRequired,
  setStates: PropTypes.func.isRequired,
};

export default FingoPreOfferFilter;
